<template>
    <v-card
        :loading="loading"
        class="mt-4"
    >
        <v-card-title>
            {{ trans('pages.billing.promoCodes') }}
        </v-card-title>
        <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-form v-on:submit="activate">
                            <v-text-field
                                v-model="slug"
                                :label="trans('fields.common.promoCode')"
                                :error-messages="errors.slug"
                                @input="clearError('slug')"
                            >
                                <template slot="append">
                                    <v-btn
                                        color="primary"
                                        @click="activate"
                                    >
                                        {{ trans('buttons.common.activate') }}
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="6" v-if="promoCodes && promoCodes.length">
                        <v-list
                            subheader
                            three-line
                        >
                            <v-subheader>{{ trans('pages.billing.activePromoCodes') }}</v-subheader>
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item v-for="promoCode in promoCodes" :key="promoCode.id" :selectable="false">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ promoCode.promo_code_item.slug }}</v-list-item-title>
                                        <v-list-item-subtitle v-text="trans('pages.billing.promoCodeDescription', {period:promoCode.period, active_to: $options.filters.dateFormat(promoCode.active_to, 'YYYY-MM-DD'), discount: promoCode.discount})"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>

                </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "promoCodes",
        mixins: [formValidate],
        props: {

        },
        data(){
            return {
                slug: '',

                activateUrl: this.route('user.promoCodes.activate'),
                indexUrl: this.route('user.promoCodes.index'),

                promoCodes: null,
                loading: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                this.loading = true;
                axios.get(this.indexUrl).then( response => {
                    this.promoCodes = response.data;
                }).catch( err => {
                    console.log(err)
                }).then( () => {
                    this.loading = false;
                })
            },
            activate(){
                this.loading = true;
                axios.post(this.activateUrl, {slug: this.slug}).then( () => {
                    this.load()
                    this.$emit('activated')
                }).catch( err => {
                    this.errors = err.response.data.errors;
                }).then( () => {
                    this.loading = false;
                })
            }
        }
    }
</script>
