<template>
    <v-container fluid>
        <v-card
            :loading="!item || !options"
        >
            <v-card-text v-if="item && options">
                <v-row>
                    <v-col cols="4">{{ trans('fields.common.plan') }}:</v-col>
                    <v-col cols="8">
                        {{ item.plan.translations[$store.getters.curlocale] }}
                        <v-btn x-small text color="primary" @click="changePlanDialogShow">
                            {{ trans('buttons.common.change') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">{{ trans('fields.plan.valid_till') }}:</v-col>
                    <v-col cols="8">
                        <plan-valid-till :item="item"></plan-valid-till>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">{{ trans('fields.plan.plan_period') }}:</v-col>
                    <v-col cols="8">
                        {{ options.planPeriodsKeyed[item.plan_period] }}
                        <v-btn x-small text color="primary" @click="changePeriodDialogShow">
                            {{ trans('buttons.common.change') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">{{ trans('fields.plan.next_payment') }}:</v-col>
                    <v-col cols="8">
                        <template
                            v-if="item.next_payment_date"
                        >
                            {{ item.next_payment_date | dateFormat('YYYY-MM-DD') }}
                            <template
                                v-if="item.next_payment_amount"
                            >
                                {{ trans('fields.plan.next_payment_amount', {amount: item.next_payment_amount, currency: item.next_payment_currency}) }}
                                <span v-if="item.next_payment_discount" class="text-red">(скидка {{item.next_payment_discount}} {{item.next_payment_currency}})</span>
                            </template>
                        </template>
                        <template
                            v-else
                        >
                            {{ trans('pages.plan.never') }}
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">{{ trans('fields.common.balance') }}:</v-col>
                    <v-col cols="8">
                        {{ item.balance }} RUB
                        <v-btn x-small text color="primary" @click="showTopUpDialog">
                            {{ trans('pages.plan.topUp') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">{{ trans('pages.plan.payment_method') }}:</v-col>
                    <v-col cols="8">
                        <template
                            v-if="item.billing_data"
                        >
                            {{ trans('pages.plan.attachedCard', {mask: item.billing_data.card_mask}) }}
                            <v-btn x-small text color="primary" @click="detachCardDialogShow">
                                {{ trans('pages.plan.detachCard') }}
                            </v-btn>
                        </template>
                        <template
                            v-else
                        >
                            {{ trans('pages.plan.noPaymentMethod') }}
                            <v-btn :disabled="topupLoading" x-small text color="primary" @click="attachCard">
                                {{ trans('pages.plan.attachCard') }}
                            </v-btn>
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-dialog v-model="changePeriodFormDialog" max-width="800">
                <change-period-form
                    v-if="changePeriodFormDialogLoaded"
                    v-on:saved="changePeriodDialogClose"
                    v-on:close="changePeriodDialogClose"
                ></change-period-form>
            </v-dialog>
            <v-dialog
                v-if="item"
                v-model="topUpFormDialog"
                max-width="400"
                v-on:click:outside="hideTopUpDialog"
            >
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :label="trans('pages.plan.topUpSum')"
                                        v-model="topUpSum"
                                    >
                                        <template slot="append">
                                            {{ item.next_payment_currency }}
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn :disabled="topupLoading" color="primary" @click="topUp">
                                        {{ trans('pages.plan.topUp') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog fullscreen v-model="changePlanFormDialog">
                <change-plan-form
                    v-if="changePlanFormDialogLoaded"
                    v-on:saved="changePlanDialogClose"
                    v-on:close="changePlanDialogClose"
                ></change-plan-form>
            </v-dialog>

        </v-card>
        <v-card
            v-if="item && options && item.next_plan_id"
            :loading="!item || !options"
            class="mt-4"
        >
            <v-card-text v-if="item && options">
                <v-row>
                    <v-col cols="12">{{ trans('pages.plan.changePlanQueued', {date: $options.filters.dateFormat(item.plan_valid_till, 'YYYY-MM-DD'), plan: item.next_plan.translations[$store.getters.curlocale], period: options.planPeriodsKeyed[item.next_plan_period]}) }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <promo-codes v-on:activated="load"></promo-codes>
        <v-card class="mt-4">
            <v-card-title>
                {{ trans('pages.billing.transactions') }}
            </v-card-title>
            <v-card-text v-if="item && options">
                <transaction-table></transaction-table>
            </v-card-text>
        </v-card>
        <detach-card-dialog
            v-if="item && item.billing_data"
            :dialogTrigger="detachDialog"
            :item-id="item.billing_data.id"
            @closed="detachDialogClosed"
            @detached="detachDialogDetached"
        >
            <template v-slot:title>
                {{ trans('pages.plan.detachDialogTitle') }}
            </template>
            <template v-slot:text>
                {{ trans('pages.plan.detachDialogText') }}
            </template>
        </detach-card-dialog>
    </v-container>
</template>

<script>

    import hasOptions from "@/mixins/hasOptions";
    import ChangePeriodForm from "./ChangePeriodForm";
    import ChangePlanForm from "./ChangePlanForm";
    import DetachCardDialog from "./DetachCardDialog";
    import PromoCodes from "../promoCode/promoCodes";
    import TransactionTable from "../transaction/TransactionTable";
    import PlanValidTill from "@/components/user/billing/PlanValidTill";

    export default {
        name: "BillingPage",
        components: {
            PlanValidTill,
            TransactionTable,
            PromoCodes,
            DetachCardDialog,
            ChangePeriodForm,
            ChangePlanForm,
        },
        mixins: [
            hasOptions
        ],
        data() {
            return {
                changePeriodFormDialog: false,
                changePeriodFormDialogLoaded: false,

                changePlanFormDialog: false,
                changePlanFormDialogLoaded: false,

                attachCardFormDialog: false,
                attachCardFormDialogLoaded: false,

                detachDialog: false,

                topupLoading: false,
                topUpFormDialog: false,
                topUpSum: 0,

                url: this.route('user.billing.index'),
                item: null,
            }
        },
        created() {
            this.load();
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            detachDialogClosed(){
                this.detachDialog = false;
            },
            detachDialogDetached(){
                this.detachDialog = false;
                this.load();
            },
            detachCardDialogShow(){
                this.detachDialog = true;
            },
            showTopUpDialog(){
                this.topUpFormDialog = true;
                this.topUpSum = this.item.next_payment_amount;
            },
            hideTopUpDialog(){
                this.topUpFormDialog = false;
            },
            topUp(){
                this.topupLoading = true;
                this.$store.dispatch('reachGoal', 'topUpPressed');
                axios.post(this.route('user.billing.topUp'), {
                    amount: this.topUpSum,
                    currency: this.item.next_payment_currency,
                }).then( response => {
                    window.location.href = response.data.confirmationUrl;
                }).catch( err => {
                    console.log(err);
                }).then( () => {
                    this.topupLoading = false;
                })
            },
            attachCard(){
                this.topupLoading = true;
                this.$store.dispatch('reachGoal', 'attachCardPressed');
                axios.post(this.route('user.billing.attachCard')).then( response => {
                    window.location.href = response.data.confirmationUrl;
                }).catch( err => {
                    console.log(err);
                }).then( () => {
                    this.topupLoading = false;
                })
            },
            changePlanDialogShow(){
                this.changePlanFormDialog = true;
                this.changePlanFormDialogLoaded = true;
            },
            changePeriodDialogShow(){
                this.changePeriodFormDialog = true;
                this.changePeriodFormDialogLoaded = true;
            },
            load(){
                this.loadOptions({
                    planPeriods: true,

                })
                axios.get(this.url).then(response => {
                    this.item = response.data;
                }).catch(err => {
                    console.log(err);
                })
            },
            changePeriodDialogClose() {
                this.changePeriodFormDialog = false;
                setTimeout(() => {
                    this.changePeriodFormDialogLoaded = false;
                    this.load();
                }, 300);
            },
            changePlanDialogClose() {
                this.changePlanFormDialog = false;
                setTimeout(() => {
                    this.changePlanFormDialogLoaded = false;
                    this.load();
                }, 300);
            },


        },
        computed: {
        }
    }
</script>

<style scoped>
    .small-caption {
        font-size: 9px;
        margin-left: -6px;
    }
</style>
