var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paginationItems && _vm.options && _vm.options.transactionStatusesKeyed)?_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.paginationItems.data,"items-per-page":_vm.paginationParams.per_page,"page":_vm.paginationParams.page,"server-items-length":_vm.paginationItems.total,"loading":_vm.loading,"footer-props":_vm.footerProps,"sort-by":"id"},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeItemsPerPage},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.created_at))+" ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sum)+" RUB ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.options.transactionStatusesKeyed[item.status])+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.options.transactionTypesKeyed[item.type])+" "+_vm._s(item.description)+" ")]}}],null,false,3853267970)}):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }