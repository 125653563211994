export default {
    data(){
        return {
            errors: {},
        }
    },
    created() {
    },
    methods: {
        clearError(err){
            if (typeof _.get(this.errors, err) !== 'undefined'){
                _.set(this.errors, err, undefined);
            }
            // if (typeof this.errors[err] !== 'undefined'){
            //     delete this.errors[err];
            // }
        },
        getErrors(path){
            return _.get(this.errors, path);
        }
    },
    computed: {

    }

}
