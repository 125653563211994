<template>
    <v-data-table
        v-if="paginationItems && options && options.transactionStatusesKeyed"
        :headers="headers"
        :items="paginationItems.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="paginationItems.total"
        :loading="loading"
        :footer-props="footerProps"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
    >
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.sum="{ item }">
            {{ item.sum }} RUB
        </template>
        <template v-slot:item.status="{ item }">
            {{ options.transactionStatusesKeyed[item.status] }}
        </template>
        <template v-slot:item.type="{ item }">
            {{ options.transactionTypesKeyed[item.type] }} {{ item.description }}
        </template>
    </v-data-table>
    <div v-else></div>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    export default {
        name: "TransactionTable",
        components: {
        },
        mixins: [
            tableWithPagination,
        ],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                transactions: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.date"), value: "created_at", sortable: false },
                    { text: this.trans("fields.transaction.type"), sortable: false, value: "type" },
                    { text: this.trans("fields.transaction.status"), sortable: false, value: "status" },
                    { text: this.trans("fields.transaction.sum"), sortable: false, value: "sum" },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('user.billing.transactions'),

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.loadOptions({transactionTypes: true, transactionStatuses: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {

        },
        computed: {
            paginationItems: {
                get(){
                    return this.transactions;
                },
                set(v){
                    this.transactions = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
