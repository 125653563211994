<template>
    <v-card
        :loading="!(item && options)"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="item && options">
                    <v-col cols="12" md="4">
                        <v-select
                            :items="options.planPeriods"
                            :label="trans('fields.common.planPeriod')"
                            :multiple="false"
                            v-model="item.plan_period"
                            :error-messages="errors.plan_period"
                            @input="clearError('plan_period')"
                        ></v-select>
                        <p v-if="price" v-html="trans('pages.plan.planPeriodChangeprice',{price: price})"></p>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="item && options">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.close') }}</v-btn>
            <v-btn color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";

    export default {
        name: "ChangePeriodForm",
        mixins: [
            formValidate,
        ],
        components: {
        },
        data(){
            return {
                showRoute: 'user.billing.planPeriod',
                updateRoute: 'user.billing.planPeriod',
                item: null,
                price: null,
                error: null,
                options: null,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                axios.get(this.route(this.showRoute)).then(response => {
                    this.item = response.data;
                    this.loadOptions();
                }).catch(err => {
                    this.error = err.response.data;
                });
            },

            loadOptions(){
                axios.post(this.route('user.options'), {
                    planPeriods: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                axios.post(this.route(this.updateRoute), this.item).then((response) => {
                    console.log('save success');
                    this.item = response.data;
                    this.$emit('saved');
                }).catch( (err) => {
                    this.errors = err.response.data.errors;
                    console.log(err.response.data);
                })
            },
        },
        computed: {
            formTitle() {
                return this.trans('pages.plan.planChangePeriodDialogTitle');
            },
        }
    }
</script>
<style>
    .draggable {
        cursor: row-resize;
    }
</style>
