<template>
    <v-card
        :loading="!(plans && options)"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="plans && options">
                    <v-col align-self="center">
                        <v-radio-group v-model="item.plan_period" row :error-messages="errors.plan_period">
                            <v-radio v-for="period in options.planPeriods" :label="period.text" :value="period.value" :key="period.value"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row v-if="plans && options">
                    <v-col>
                        <v-card
                            :color="item.plan_id === plans.currentPlan.id ? 'blue' : ''"
                            :dark="item.plan_id === plans.currentPlan.id"
                            class="position-relative"
                        >
                            <div v-if="item.plan_period == 'halfYearly' && plans.currentPlan.price.halfYearly" class="discount">
                                - {{ Math.floor((1 - (Number(plans.currentPlan.price.halfYearly)/6) / Number(plans.currentPlan.price.monthly))*100) }} %
                            </div>
                            <div v-if="item.plan_period == 'yearly' && plans.currentPlan.price.yearly" class="discount">
                                - {{ Math.floor((1 - (Number(plans.currentPlan.price.yearly)/12) / Number(plans.currentPlan.price.monthly))*100) }} %
                            </div>

                            <v-card-title>
                                {{ plans.currentPlan.translations[$store.getters.curlocale] }}
                            </v-card-title>
                            <v-card-subtitle>
                                {{ trans('pages.plan.yourCurrentPlan') }}
                            </v-card-subtitle>
                            <v-card-text>
                                <div class="v-data-table v-data-table--dense">
                                    <table>
                                        <tbody>
                                        <tr v-for="(desc, index) in plans.currentPlan.description" :key="index">
                                            <td>{{ desc.label }}</td>
                                            <td>{{ desc.value }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ trans('fields.plan.priceFor') }} {{ trans('fields.plan.'+item.plan_period) }}</td>
                                            <td v-if="plans.currentPlan.price[item.plan_period]">
                                                <h2>{{ plans.currentPlan.price[item.plan_period] }} {{ plans.currentPlan.price.currency }}</h2>
                                            </td>
                                            <td v-else>{{ trans('pages.plan.free') }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </v-card-text>
                            <div class="text-center pt-4 pb-4">
                                <v-btn v-if="item.plan_id !== plans.currentPlan.id" color="primary" dark @click="selectPlan(plans.currentPlan.id)">
                                    {{ trans('buttons.plan.select') }}
                                </v-btn>
                                <v-btn v-else color="primary" disabled>
                                    {{ trans('buttons.plan.selected') }}
                                </v-btn>
                            </div>

                        </v-card>
                    </v-col>
                    <v-col v-for="plan in filteredPlans" :key="plan.id">
                        <v-card :color="item.plan_id === plan.id ? 'blue' : ''" :dark="item.plan_id === plan.id">
                            <div v-if="item.plan_period == 'halfYearly' && plan.price.halfYearly" class="discount">
                                - {{ Math.floor((1 - (Number(plan.price.halfYearly)/6) / Number(plan.price.monthly))*100) }} %
                            </div>
                            <div v-if="item.plan_period == 'yearly' && plan.price.yearly" class="discount">
                                - {{ Math.floor((1 - (Number(plan.price.yearly)/12) / Number(plan.price.monthly))*100) }} %
                            </div>
                            <v-card-title>
                                {{ plan.translations[$store.getters.curlocale] }}
                            </v-card-title>
                            <v-card-subtitle v-if="plan.id === plans.currentPlan.id">
                                {{ trans('pages.plan.yourCurrentPlan') }}
                            </v-card-subtitle>
                            <v-card-subtitle v-else>
                            </v-card-subtitle>
                            <v-card-text>
                                <div class="v-data-table v-data-table--dense">
                                    <table>
                                        <tbody>
                                        <tr v-for="(desc, index) in plan.description" :key="index">
                                            <td>{{ desc.label }}</td>
                                            <td>{{ desc.value }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ trans('fields.plan.priceFor') }} {{ trans('fields.plan.'+item.plan_period) }}</td>
                                            <td v-if="plan.price[item.plan_period]">
                                                <h3>{{ plan.price[item.plan_period] }} {{ plan.price.currency }}</h3>
                                            </td>
                                            <td v-else>{{ trans('pages.plan.free') }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </v-card-text>
                            <div class="text-center pt-4 pb-4">
                                <v-btn v-if="item.plan_id !== plan.id" color="primary" dark @click="selectPlan(plan.id)">
                                    {{ trans('buttons.plan.select') }}
                                </v-btn>
                                <v-btn v-else color="primary" disabled>
                                    {{ trans('buttons.plan.selected') }}
                                </v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="item && options">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.close') }}</v-btn>
            <v-btn color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";

    export default {
        name: "ChangePlanForm",
        mixins: [
            formValidate,
        ],
        components: {
        },
        props: {
        },
        data(){
            return {
                showRoute: 'user.billing.plans',
                updateRoute: 'user.billing.changePlan',
                item: {
                    plan_period: null,
                    plan_id: null,
                },
                plans: null,
                error: null,
                options: null,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                axios.get(this.route(this.showRoute)).then(response => {
                    this.plans = response.data;
                    this.item.plan_period = this.plans.plan_period;
                    this.item.plan_id = this.plans.currentPlan.id;
                    this.loadOptions();
                }).catch(err => {
                    this.error = err.response.data;
                });
            },

            loadOptions(){
                axios.post(this.route('user.options'), {
                    planPeriods: true,
                    plans: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            selectPlan(id){
                this.item.plan_id = id;
            },
            save() {
                axios.post(this.route(this.updateRoute), this.item).then((response) => {
                    console.log('save success');
                    this.item = response.data;
                    this.$emit('saved');
                }).catch( (err) => {
                    this.errors = err.response.data.errors;
                    console.log(err.response.data);
                })
            },
        },
        computed: {
            formTitle() {
                return this.trans('pages.plan.planChangeDialogTitle');
            },
            filteredPlans() {
                if (!this.plans) return [];
                return _.filter(this.plans.plans, (plan) => {
                    return plan.id !== this.plans.currentPlan.id;
                })
            }
        }
    }
</script>
<style scoped>
    .v-data-table table {
        width: 100%;
        border-spacing: 0;
    }
    .v-data-table--dense td {
        height: 24px;
    }
     .v-data-table td {
         font-size: 0.875rem;
         height: 48px;
     }
     .v-data-table td, .v-data-table th {
         padding: 0 16px;
     }
    .discount {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 2em;
        color: white;
        background: #58b5ff;
        padding: 15px;
    }
</style>
