<template>
    <v-dialog
        v-model="dialog"
        max-width="480"
        @click:outside="clickOutside"
    >
        <v-card>
            <v-card-title v-if="title" class="headline">
                <slot name="title"></slot>
            </v-card-title>
            <v-card-text v-if="text">
                <slot name="text"></slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cancel"
                >
                    {{ trans('buttons.common.cancel') }}
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    @click="deleteItem"
                >
                    {{ trans('buttons.common.detach') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "DetachCardDialog",
        props: {
            itemId: {
                type: Number
            },
            dialogTrigger: {
                type: [Boolean, undefined, null],
                default: false,
            },
        },
        data(){
            return {
                dialog: !!this.dialogTrigger,
            }
        },
        methods: {
            cancel(){
                this.$emit('closed');
                this.dialog = false;
            },
            deleteItem(){
                axios.post(this.route('user.billing.detach', this.itemId)).then( () => {
                    this.dialog = false;
                    this.$emit('detached', this.itemId);
                }).catch( err => {
                    console.log(err);
                    this.$emit('closed');
                    this.dialog = false;
                })
                this.dialog = false;
            },
            clickOutside(){
                this.$emit('closed');
                this.dialog = false;
            }
        },
        watch: {
            dialogTriggerWatcher(nv, ov){
                if (nv !== ov && nv){
                    this.dialog = true;
                }
            }
        },
        computed: {
            title(){
                // return true;
                return this.$slots.title;
            },
            text() {
                // return true;
                return this.$slots.text;
            },
            dialogTriggerWatcher() {
                return this.dialogTrigger;
            }
        }
    }
</script>
