<template>
    <span>
        <template
                v-if="planNotPayed"
        >
            {{ trans('pages.plan.unpayed') }}
        </template>
        <template
                v-else-if="planValidTill"
        >
            {{ planValidTill | dateFormat('YYYY-MM-DD') }}
        </template>
        <template
                v-else
        >
            {{ trans('pages.plan.forever') }}
        </template>
    </span>
</template>

<script>
    export default {
        name: "PlanValidTill",
        props: {
            item: {
                type: [Object, null],
                default: null,
            },
            validTill: {
                type: String,
                default: '',
            },
            notPayed: {
                type: Boolean,
                default: false,
            }
        },
        data(){
            return {};
        },
        computed: {
            planValidTill(){
                return this.item ? this.item.plan_valid_till : this.validTill;
            },
            planNotPayed(){
                return this.item ? this.item.notpayed : this.notPayed;
            }
        }
    }
</script>

<style scoped>

</style>